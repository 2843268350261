import { find } from 'lodash'

export const useTransitionCode = (actions) => {
  const actionCode = (code) => {
    if (code === 'ACT_PRODUCT_SENT') {
      const exist = find(actions, (act) => act.split(':')[0] === code)
      if (exist) return true
      return false
    }
    return actions.includes(code)
  }

  return { actionCode }
}
