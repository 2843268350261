<template>
  <div>
    <GridInfoContainer title="付款資訊" labelWidth="120" labelGap="12">
      <template #default="slotData">
        <InfoContainerItem :data="slotData" label="付款方式" :value="displayPaymentType" />
        <InfoContainerItem :data="slotData" label="付款金額" :value="`$ ${get(order, 'MemberStoreOrderPayment.amount', '-')}`" />
        <InfoContainerItem :data="slotData" label="付款狀態">
          <div class="flex items-center" style="gap: 22px">
            <Tag :type="paymentStatus.tagType">{{ paymentStatus.label || '-' }}</Tag>
            <!-- <el-button
              v-if="checkCode('ACT_PAYMENT_REFUNDED')"
              class="text-primary-100 underline"
              type="text"
              @click="onRefund"
            >
              標記已退款
            </el-button> -->
          </div>
        </InfoContainerItem>
      </template>
    </GridInfoContainer>

    <!-- <MemberShopOrder3RdReturnModal v-if="modal.refund_3rd" @close="modal.refund_3rd = false" /> -->
    <!-- <MemberShopOrderWalletReturnModal v-if="modal.refund_wallet" @close="modal.refund_wallet = false" /> -->
    <WarningDialog
      v-if="modal.refund"
      title="標記已退款"
      content="是否確認標記為已退款？操作後無法再調整付款狀態"
      @confirm="refundOrder"
      @close="modal.refund = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import GridInfoContainer from '@/components/Container/GridInfoContainer.vue'
import InfoContainerItem from '@/components/Container/InfoContainerItem.vue'
// import MemberShopOrderWalletReturnModal from './MemberShopOrderWalletReturnModal.vue'
// import MemberShopOrder3RdReturnModal from './MemberShopOrder3RdReturnModal.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import Tag from '@/components/Tag/Tag.vue'
import { get } from 'lodash'
import { orderPaymentTypeConfig, orderPaymentStatusConfig } from '@/config/memberShop'
import { MemberShopOrderRefund } from '@/api/memberShop'
import { useTransitionCode } from '../utils'
import store from '@/store'

export default defineComponent({
  name: 'MemberShopOrderPaymentInfoSection',
  components: { GridInfoContainer, InfoContainerItem, Tag, WarningDialog },
  props: {
    order: { type: Object, default: () => ({}) },
    actions: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const modal = reactive({
      refund: false,
      refund_3rd: false,
      refund_wallet: false,
    })
    const checkCode = computed(() => {
      const actions = props.actions
      const { actionCode } = useTransitionCode(actions)
      return (code) => actionCode(code)
    })

    const displayPaymentType = computed(() => {
      const type = get(props.order, 'MemberStoreOrderPayment.paymentType')
      if (!type) return ''
      return get(orderPaymentTypeConfig[type], 'label')
    })

    const paymentStatus = computed(() => {
      const status = get(props.order, 'MemberStoreOrderPayment.status')
      return orderPaymentStatusConfig[status] || {}
    })

    const refundOrder = async () => {
      const [res, err] = await MemberShopOrderRefund({
        shopId: shopId.value,
        id: get(props.order, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('成功標記訂單已退款！')
      emit('refresh')
      modal.refund = false
    }

    const onRefund = () => {
      // const type = get(props.order, 'MemberStoreOrderPayment.paymentType')
      // if (type === 'wallet') modal.refund_wallet = true
      // else modal.refund_3rd = true
      modal.refund = true
    }

    return { get, displayPaymentType, paymentStatus, checkCode, onRefund, modal, refundOrder }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-input {
  @apply w-[150px];
}
</style>
