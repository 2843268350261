<template>
  <el-dialog
    title="訂單商品明細"
    :visible="true"
    width="700px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div class="member-shop-order-detail-modal">
      <p class="sub-title">使用狀態</p>
      <div class="search-block">
        <el-input
          v-model="search.couponId"
          clearable
          placeholder="票券編號"
        />
        <el-input
          v-model="search.exchangeCode"
          clearable
          placeholder="兌換碼"
        />
        <el-select v-model="search.useStatus" placeholder="使用狀態" clearable>
          <el-option
            v-for="item in couponStatusConfigList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <el-table :data="displayStatusData">
        <el-table-column label="票券編號" prop="code" align="center" />
        <el-table-column label="票券類型" prop="type" align="center" />
        <el-table-column label="兌換碼" prop="exchangeCode" align="center" />
        <el-table-column label="使用狀態" prop="status" align="center">
          <template slot-scope="scope">
            <Tag :type="scope.row.status.tagType">{{ scope.row.status.label }}</Tag>
          </template>
        </el-table-column>
        <el-table-column label="使用狀態更新時間" prop="updatedAt" align="center" width="180px" />
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import { formatDate } from '@/utils/date'
import { find, get, upperCase } from 'lodash'
import { couponStatusConfig } from '@/config/coupon'
import Tag from '@/components/Tag/Tag.vue'

export default defineComponent({
  name: 'MemberShopOrderDetailCouponDialog',
  components: { Tag },
  props: {
    logs: { type: Array, default: () => [] },
    products: { type: Array, default: () => [] },
    productDetail: { type: Object, default: () => {} },
  },
  setup (props) {
    const search = reactive({
      couponId: '',
      exchangeCode: '',
      useStatus: null,
    })

    const displayStatusData = computed(() => {
      let data = get(props.productDetail, 'couponRecords')
      if (search.couponId) data = data.filter(i => upperCase(get(i, 'code')).includes(upperCase(search.couponId)))
      if (search.exchangeCode) {
        data = data.filter(i => {
          return get(i, 'exchangeCode') ? upperCase(get(i, 'exchangeCode')).includes(upperCase(search.exchangeCode)) : false
        })
      }
      if (search.useStatus) data = data.filter(i => get(i, 'status') === search.useStatus)
      return data.map(item => {
        const code = get(item, 'code')
        const type = couponType(get(item, 'type'))
        const exchangeCode = get(item, 'exchangeCode') || '-'
        const status = get(item, 'status') ? renderStatus(get(item, 'status')) : '-'
        const updatedAt = get(item, 'updatedAt') ? formatDate(get(item, 'updatedAt')) : '-'

        return {
          code,
          type,
          exchangeCode,
          status,
          updatedAt,
        }
      })
    })
    const couponType = (type) => {
      const dict = {
        coupon: '使用券',
        exchange: '兌換券',
      }
      return dict[type]
    }
    const renderStatus = (status) => {
      return find(couponStatusConfigList.value, item => item.value === status)
    }
    const couponStatusConfigList = computed(() => {
      const config = couponStatusConfig
      delete config.all
      return config
    })
    const refresh = () => {
      console.log('refresh')
    }

    return {
      search,
      refresh,
      displayStatusData,
      couponStatusConfigList,
      renderStatus,
    }
  },
})
</script>

<style scoped lang="postcss">
.sub-title {
  @apply text-primary-100 font-bold mb-[16px]
}
.search-block {
  @apply grid grid-cols-3 w-full gap-[8px] mb-[16px]
}
::v-deep .search-block .el-input {
  @apply w-full
}
</style>
