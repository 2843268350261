<template>
  <div class="statistics-info-wrapper">
    <div class="statistics-info">
      <span class="name" style="color: var(--primary-100)">兌換資訊</span>
      <span class="value" style="color: var(--primary-100); font-size: 20px">{{ displayPrice }}</span>
    </div>
    <template v-if="rewards && rewards.length">
      <el-divider />
      <div v-for="(reward, index) in rewards" :key="reward.id" class="statistics-info">
        <span v-show="index === 0" class="name">訂單完成後獲得回饋</span>
        <span class="value">{{ getReward(reward) }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'MemberShopOrderStatisticSection',
  props: {
    order: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const rewards = computed(() => get(props.order, 'RewardActivityRecords'))
    const getReward = (reward) => {
      const type = get(reward, 'rewardType')
      const rewardAmount = get(reward, 'rewardAmount')
      if (type === 'POINT') return `${rewardAmount} 點`
      if (type === 'CASHBACK') return `$${rewardAmount} 回饋金`
    }
    const displayPrice = computed(() => {
      const point = get(props.order, 'pointExchangeAmount')
      const price = get(props.order, 'paidAmount')
      let displayPrice = '-'
      if (!price) displayPrice = `${point} 點`
      if (price && point) displayPrice = `${point} 點 + $ ${price}`
      return displayPrice
    })

    return { get, rewards, getReward, displayPrice }
  },
})
</script>

<style scoped lang="scss">
.statistics-info-wrapper {
  @apply mt-[12px] grid grid-flow-row place-items-end gap-[8px] py-[16px] px-[40px] bg-white;
}

.statistics-info {
  @apply flex items-center;
  .name {
    @apply text-right text-gray-80 font-normal;
  }
  .value{
    @apply text-right text-gray-100 font-medium w-[150px];
    word-break: break-all;
    padding-left: 1.25rem;
  }
}

::v-deep .el-divider--horizontal {
@apply m-0;
}
</style>
