<template>
  <el-dialog
    title="紀錄歷程"
    :visible="true"
    width="473px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div class="member-shop-order-history-modal">
      <p v-if="!logs.length">目前尚無紀錄</p>
      <el-steps v-if="logs.length" direction="vertical" :active="displayData.length" :space="81">
        <el-step v-for="log in displayData" :key="log.id" :title="log.message" :description="log.time">
          {{ log.target }}
          <template #icon>
            <div class="w-full h-full bg-primary-100 rounded-full" />
          </template>
        </el-step>
      </el-steps>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { formatDate } from '@/utils/date'
import { find, get } from 'lodash'

export default defineComponent({
  name: 'MemberShopOrderHistoryModal',
  props: {
    logs: { type: Array, default: () => [] },
    products: { type: Array, default: () => [] },
  },
  setup (props) {
    const displayData = computed(() => {
      const data = props.logs
      return data.map(log => {
        const time = formatDate(log.createdAt)
        const orderStatus = get(log, 'content.orderStatus')
        const productId = get(log, 'content.productId')
        const code = log.code
        const target = orderStatus ? find(logStruct, { code, orderStatus }) : find(logStruct, { code })
        let message = get(target, 'message')

        if (productId) {
          const product = find(props.products, { id: productId })
          message = `${product?.quantity}個 ${product?.name} ${get(target, 'message')}`
        }

        return {
          target,
          id: log.id,
          time,
          code,
          orderStatus,
          message,
        }
      })
    })

    const logStruct = [
      {
        code: 'ORDER_STATUS_CHANGED',
        orderStatus: 'pending',
        message: '訂單確認中',
      },
      {
        code: 'ORDER_STATUS_CHANGED',
        orderStatus: 'confirmed',
        message: '訂單已確認',
      },
      {
        code: 'ACT_PAYMENT_PAID',
        message: '訂單已付款',
      },
      {
        code: 'ORDER_STATUS_CHANGED',
        orderStatus: 'finished',
        message: '訂單已完成',
      },
      {
        code: 'ORDER_STATUS_CHANGED',
        orderStatus: 'cancel',
        message: '訂單已取消',
      },
      {
        code: 'ORDER_STATUS_CHANGED',
        orderStatus: 'invalid',
        message: '訂單不成立',
      },
      // ACT
      {
        code: 'ACT_ORDER_CANCELLED',
        message: '後台操作訂單取消',
      },
      {
        code: 'ACT_PAYMENT_PAID',
        message: '已付款',
      },
      {
        code: 'ACT_PAYMENT_FAILED',
        message: '付款失敗',
      },
      {
        code: 'ACT_SYSTEM_PAYMENT_TIMEOUT',
        message: '付款逾期',
      },
      {
        code: 'ACT_PAYMENT_REFUNDED',
        message: '已退款',
      },
      {
        code: 'ACT_PRODUCT_SENT',
        message: '已發送',
      },
      {
        code: 'ACT_PRODUCT_RECYCLED',
        message: '已回收',
      },
      {
        code: 'ACT_INVOICE_ISSUED',
        message: '發票已開立',
      },
      {
        code: 'ACT_INVOICE_VOIDED',
        message: '發票已作廢',
      },
      {
        code: 'ACT_INVOICE_RETURNED',
        message: '發票已折讓',
      },

    ]
    return { displayData }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .is-finish {
  @apply text-gray-100;
}

.member-shop-order-history-modal {
  @apply max-h-[525px] overflow-y-auto;
}
</style>
