<template>
  <GridInfoContainer title="顧客資訊" labelWidth="120" labelGap="12">
    <template #default="slotData">
      <InfoContainerItem :data="slotData" label="顧客姓名" :value="get(member, 'UserInfo.name') || '-'" />
      <InfoContainerItem :data="slotData" label="顧客信箱" :value="get(member, 'UserInfo.email') || '-'" />
    </template>
  </GridInfoContainer>
</template>

<script>
import GridInfoContainer from '@/components/Container/GridInfoContainer.vue'
import InfoContainerItem from '@/components/Container/InfoContainerItem.vue'
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'MemberShopOrderCustomerInfoSection',
  components: { GridInfoContainer, InfoContainerItem },
  props: {
    order: { type: Object, default: () => ({}) },
    actions: { type: Array, default: () => [] },
  },
  setup (props) {
    const member = computed(() => get(props.order, 'Member'))

    return { member, get }
  },
})
</script>

<style scoped lang="postcss">

</style>
